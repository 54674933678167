<template>
    <div v-if="id != ''">
        <a-modal :visible="visible" :centered="true" :footer="null" @cancel="closeModal">
            <BHLoading :show="loading" />
            <h5>Add Secondary Contact/Purchaser</h5>
            <a-form-model class="mt-3" ref="connection" :model="connection">
                <a-form-model-item prop="person" label="Search for a lead">
                    <searchContact v-if="visible" @updatecontact="updatePerson" :allowClear="true"/>
                </a-form-model-item>
			</a-form-model>
			<hr />
			<a-form-model class="mt-3" ref="newconnection" :model="newConnection">
				<h6>Add a new lead</h6>
				<a-row :gutter="16">
					<a-col :span="24">
						<a-form-model-item required :rules="req('Please enter an email')" prop="email" label="Email">
							<a-input v-model="newConnection.email" :disabled="connection.person != ''" size="large" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item required :rules="req('Please enter a First Name')" prop="firstName" label="First Name">
							<a-input v-model="newConnection.firstName" :disabled="connection.person != ''" size="large" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item required :rules="req('Please enter a Last Name')" prop="firstName" label="Last Name">
							<a-input v-model="newConnection.lastName" :disabled="connection.person != ''" size="large" />
						</a-form-model-item>
					</a-col>
				</a-row>
            </a-form-model>
            <div class="dF jE mt-2">
                <a-button @click="closeModal" class="cancel-button mr-3" size="large">CANCEL</a-button>
                <a-button @click="submitConnection" type="primary" size="large">ADD CONNECTION</a-button>
            </div>
        </a-modal>
        <a-card>
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add Secondary Contact</div>
                    <a-icon @click="addConnection" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <template v-if="connections.length">
                <div :class="connI == 0 ? '':'mt-3'" class="dF jSB" v-for="(conn, connI) in connections" :key="conn.id" :value="conn.id">
                    <div class="dF">
                        <a-avatar class="mr-3" style="font-size: 20px; background-color:#1070CA" shape="circle" :size="40">{{conn.firstName && conn.lastName && conn.firstName != '' && conn.lastName != '' ? conn.firstName[0].toUpperCase() + conn.lastName[0].toUpperCase() : '--'}}</a-avatar>
                        <div>
                            <div style="font-size:15px; color:var(--orange)">{{conn.firstName && conn.lastName && conn.firstName != '' && conn.lastName != '' ? `${conn.firstName} ${conn.lastName}` : ''}}</div>
                            <div v-if="conn.jobTitle && conn.company && conn.jobTitle != '' && conn.company != ''" class="dF aC"><div>{{`${conn.jobTitle} at`}}</div>&nbsp;<div style="color:var(--orange)">{{conn.company}}</div></div>
                            <div else>{{conn.jobTitle && conn.jobTitle != '' && (!conn.company || conn.company == '') ? conn.jobTitle : conn.company && conn.compnay != '' && (!conn.jobTitle || conn.jobTitle == '') ? conn.company : ''}}</div>
                            <div v-if="conn.phone || conn.email" class="dF aC"><div v-if="conn.phone">{{`${displayPhone(conn.phone)}`}}</div><div v-if="conn.phone && conn.email">&nbsp;&nbsp;|&nbsp;&nbsp;</div><div v-if="conn.email" style="color:var(--orange)">{{conn.email}}</div></div>
                        </div>
                    </div>
                    <a-icon @click="removeConnection(conn.id)" style="cursor:pointer" type="close" />
                </div>
            </template>
            <template v-else>This lead doesn't have any secondary contacts</template>
        </a-card>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'
export default {
    components:{
        BHLoading,
        searchContact
    },
    props:{
        id:{
            type:String,
            default:''
        }
    },
    data() {
        return{
            loading:false,
            visible:false,
            connection:{
                person:''
            },
			newConnection:{
				email:'',
				firstName:'',
				lastName:''
			},
            update:0
        }
    },
    computed:{
        opportunities() {
            return this.$store.state.contacts.opportunities || []
        },
        allContacts() {
            return this.$store.state.contacts.allContacts || {}
        },
        externalContacts(){
            return this.$store.state.contacts.externalContacts || {}
        },
        connections() {
            let time = this.update
            let connections = []
            if (this.id != '') {
                let find = this.opportunities.find(x => x.id == this.id)
                if (find && find.secondaryContacts && find.secondaryContacts.length) {
                    if (typeof find.secondaryContacts[0] == 'object') {
                        return JSON.parse(JSON.stringify(find.secondaryContacts))
                    }
                    else if (typeof find.secondaryContacts[0] == 'string') {
                        find.secondaryContacts.forEach(async (id) => {
                            if (this.allContacts[id]){
                                connections.push(this.allContacts[id])
                            } else if (this.externalContacts[id]){
                                connections.push(this.externalContacts[id])
                            } else {
                                this.loading = true
                                await this.$api.get(`/contacts/:instance/${id}`).then( ({data}) => {
                                    this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                                    connections.push(data)
                                    this.loading = false
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
                            }
                        })
                    }
                }
            }
            return connections
        }
    },
    methods:{
        removeConnection(id) {
            console.log('id',id)
            let find = this.opportunities.find(x => x.id == this.id)
            if (find.contact && find.contact.id && this.$store.state.contacts.allContacts[find.contact.id]) {
                let sendObj = JSON.parse(JSON.stringify(find))

                if (sendObj.secondaryContacts && sendObj.secondaryContacts.length && typeof sendObj.secondaryContacts[0] == 'object') sendObj.secondaryContacts = sendObj.secondaryContacts.map(x => x = x.id)
                let index = sendObj.secondaryContacts.findIndex(x => x==id)
                if (index != -1) sendObj.secondaryContacts.splice(index, 1)
                this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                    this.$store.commit('UPDATE_OPPORTUNITY', data )
                    this.update = Date.now()
                    this.$emit('update')
                }).catch( err => {
                    this.$message.error(this.$err(err))
                })
            }
        },
        displayPhone(num){
			if(!num) return '';
            if (num != 'n/a' && num != '' && num != undefined) {
                let phone = num.toString().trim("").replace(/[^a-zA-Z0-9 ]/g, '')
                let first = phone.substring(0,3)
                let middle = phone.substring(3,6)
                let last = phone.substring(6,10)

                return '('+first+') '+middle+' '+last
            } else {
                return ''
            }
        },
        req:msg=>({required:true,message:msg}),
        submitConnection() {
			this.loading = true
			if (this.connection.person != ''){
				this.submitExisting()
			} else {
				this.$refs.newconnection.validate(valid => {
					if (valid) {
						let sendObj = JSON.parse(JSON.stringify(this.newConnection))
						this.$api.post(`/contacts/:instance`, sendObj).then( ({data}) => {
							this.submitNew(data.id)
							this.$store.commit('SET_TOTAL_RESULT', this.totalContacts + 1)
						}).catch (err => {
							this.$message.error('Contact already exists.')
							this.loading = false
						})
					} else {
						this.loading = false
					}
				})
			}

        },
		submitExisting(){
			let find = this.opportunities.find(x => x.id == this.id)
			if(!find.contact) {
				this.loading = false
				return this.$message.error('This opportunity does not have a primary contact. Please add a primary contact first.')
			}

			if (find.contact.id && this.connection.person != find.contact.id) {
				let sendObj = JSON.parse(JSON.stringify(find))
				if (sendObj.secondaryContacts && sendObj.secondaryContacts.length && typeof sendObj.secondaryContacts[0] == 'object') sendObj.secondaryContacts = sendObj.secondaryContacts.map(x => x = x.id)

				if (!sendObj.secondaryContacts.includes(this.connection.person)) {
					sendObj.secondaryContacts.push(this.connection.person)
					this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
						this.$store.commit('UPDATE_OPPORTUNITY', data)
						this.closeModal()
						this.$emit('update')
						this.update = Date.now()
						this.loading = false
					}).catch( err => {
						this.$message.error(this.$err(err))
						this.loading = false
					})
				} else {
					this.loading = false
					return this.$message.error('This contact has already been added!')
				}
			} else {
				if (this.connection.person == find.contact.id) {
					this.loading = false
					return this.$message.error('The contact you selected is the contact of the opportunity')
				}
				this.loading = false
			}
		},
		submitNew(newid){
			let find = this.opportunities.find(x => x.id == this.id)
			if(!find.contact) {
				this.loading = false
				return this.$message.error('This opportunity does not have a primary contact. Please add a primary contact first.')
			}

			if (find.contact.id && newid != find.contact.id) {
				let sendObj = JSON.parse(JSON.stringify(find))
				if (sendObj.secondaryContacts && sendObj.secondaryContacts.length && typeof sendObj.secondaryContacts[0] == 'object') sendObj.secondaryContacts = sendObj.secondaryContacts.map(x => x = x.id)

				if (!sendObj.secondaryContacts.includes(newid)) {
					sendObj.secondaryContacts.push(newid)
					this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
						this.$store.commit('UPDATE_OPPORTUNITY', data)
						this.closeModal()
						this.$emit('update')
						this.update = Date.now()
						this.loading = false
					}).catch( err => {
						this.$message.error(this.$err(err))
						this.loading = false
					})
				} else {
					this.loading = false
					return this.$message.error('This contact has already been added!')
				}
			} else {
				if (this.connection.person == find.contact.id) {
					this.loading = false
					return this.$message.error('The contact you selected is the contact of the opportunity')
				}
				this.loading = false
			}
		},
        closeModal() {
            this.visible = false
			if(this.$refs.newconnection){
				this.$refs.newconnection.resetFields()
			}
            this.connection.person = ''
			this.newConnection.firstName = ''
			this.newConnection.lastName = ''
			this.newConnection.email = ''
        },
        async updatePerson(contact) {
			if (!contact) {
				this.connection.person = ''
				this.newConnection.firstName = ''
				this.newConnection.lastName = ''
				this.newConnection.email = ''
				return;
			}
			try {
				this.connection.person = contact
				let {data} = await this.$api.get(`/contacts/:instance/${contact}`)
				this.newConnection.firstName = data.firstName
				this.newConnection.lastName = data.lastName
				this.newConnection.email = data.email
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },
        addConnection() {
            let find = this.opportunities.find(x => x.id == this.id)
            if (find){
                if (find.stage == 'transaction'){
                    return this.$message.error('You cannot add a secondary contact to an opportunity that is currently in transaction.')
                } else {
                    console.log('add')
                    this.visible = true
                }
            }
        }
    }
}
</script>

<style>

</style>
