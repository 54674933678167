<template>
    <div>
        <BHLoading :show="loading" />
        <a-modal :centered="true" :footer="null" :visible="previewModal.visible" @cancel="onClose" :width="'auto'">
            <template v-if="previewModal.visible && Object.keys(previewModal.file).length != 0">
                <img style="margin:10px; width:auto; max-width:600px; max-height:600px; object-fit:cover" v-if="previewModal.file.label.includes('png') || previewModal.file.label.includes('jpeg') || previewModal.file.label.includes('jpg') || previewModal.file.label.includes('svg')" :src="previewModal.file.value" />
            </template>
        </a-modal>
        <a-card>
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Attachment</div>
                    <a-icon @click="uploadFiles" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <template v-if="attachments.length">
                <div :class="attachmentI == 0 ? '' : 'mt-4'" class="dF jSB" v-for="(attachment, attachmentI) in attachments" :key="attachmentI" :value="attachmentI">
                    <div class="dF">
                        <div class="smallIcon mr-3 dF aC jC" style="background-color: #FFDEAD;">
                            <i style="font-size:16px; color:var(--orange)" class="fa fa-file" />
                        </div>
                        <div>
                            <div style="color:var(--orange); font-size:15px">{{attachment.label}}</div>
                        </div>
                    </div>
                    <div>
                        <a-tooltip class="mr-2" slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">View Attachment</template>
                            <a-icon v-if="attachment.label.includes('png') || attachment.label.includes('jpeg') || attachment.label.includes('jpg') || attachment.label.includes('svg') || attachment.label.includes('JPG')" @click="viewAttachment(attachment)" style="font-size:16px" class="action-button" type="eye" />
                            <a v-else :href="attachment.value" target="_blank"><a-icon style="font-size:16px;cursor:pointer" type="eye" /></a>
                        </a-tooltip>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Download Attachment</template>
                            <a :href="attachment.value" target="_blank"><i style="font-size:16px;cursor:pointer" class="fe fe-download" /></a>
                        </a-tooltip>
                    </div>
                </div>
            </template>
			<template v-else>
				This lead doesn't have any attachments
			</template>
        </a-card>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'

export default {
    components:{BHLoading},
    props:{
        id:{
            type:String,
            default:''
        },
        contact:{
            type:Object,
            default:() => {}
        }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        allContacts() {
            return this.$store.state.contacts.allContacts
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts
        }
    },
    data() {
        return{
            forms:[],
            attachments:[],
            loading:false,
            previewModal:{
                visible:false,
                file:{}
            }
        }
    },
    methods:{
		uploadFiles(){
			this.$store.commit('SET_MULTIPLE_IMAGES', true)
			this.$store.commit('MEDIA_SELECT',{callback:this.selectFile,type:'any', multiple:true})
		},
        viewAttachment(file) {
            if (file.label.includes('png') || file.label.includes('jpg') || file.label.includes('jpeg') || file.label.includes('svg') || file.label.includes('JPG')) {
                this.previewModal.visible = true
                this.previewModal.file = file
            }
        },
        onClose() {
            this.previewModal.visible = false
            this.previewModal.file = {}
        },
        async selectFile(files){
            console.log('fileasdf', this.id)
            let sendFiles = [];
			files.forEach(x => {
				let obj = {}
				obj.label = x.name
				obj.value = x.url

				sendFiles.push(obj)
			})

            let sendObj = {}
            if (this.id != '') {
                if (this.allContacts[this.id]) {
                    sendObj = JSON.parse(JSON.stringify(this.allContacts[this.id]))
                } else if (this.externalContacts[this.id]) {
                    sendObj = JSON.parse(JSON.stringify(this.externalContacts[this.id]))
                } else {
                    this.loading = true
                    await this.$api.get(`/contacts/:instance/${this.id}`).then( ({data}) => {
                        this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                        sendObj = JSON.parse(JSON.stringify(data))
                        this.loading = false
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            } else if (Object.keys(this.contact).length != 0 && this.contact && this.contact != undefined) {
                console.log('adsfs',this.contact.id)
                if (this.allContacts[this.contact.id]) {
                    sendObj = JSON.parse(JSON.stringify(this.allContacts[this.contact.id]))
                } else if (this.externalContacts[this.contact.id]) {
                    sendObj = JSON.parse(JSON.stringify(this.externalContacts[this.contact.id]))
                } else {
                    this.loading = true
                    await this.$api.get(`/contacts/:instance/${this.contact.id}`).then( ({data}) => {
                        this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                        sendObj = JSON.parse(JSON.stringify(data))
                        this.loading = false
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            }
            if (sendObj.others && sendObj.others.attachments) {
                sendFiles.forEach(fileObj => {
                    sendObj.others.attachments.push(fileObj)
                })
            } else {
                sendObj.others = {}
                sendObj.others.attachments = sendFiles
            }
            console.log('sendObj', sendObj)
            this.$api.put(`/contacts/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                this.$store.commit('SET_PROP', {where:['allContacts',data.id],what:data} )
                sendFiles.forEach(fileObj => {
                    this.attachments.push(fileObj)
                })
                this.$store.commit('LOAD', false)
                sendFiles.forEach(fileObj => {
                    this.$emit('update', fileObj)
                })
            }).catch( err => {
                this.$message.error(this.$err(err))
            })
        },
        getDateString(string) {
            if (string != '' && string != undefined) {
                let year = string.substring(0,4)
                let month = string.substring(5,7)
                let day = string.substring(8,10)
				if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
				else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
				else {
					return `${month}/${day}/${year}`
				}
            } else {return ''}
        },
    },
    created() {
        console.log('contact and id', this.id, this.contact)
        if (this.id != '' && this.id && this.id != undefined && (!this.contact || this.contact == undefined)) {
            this.loading = true
            this.$api.get(`/form-submits/:instance?contact=${this.id}`).then(({data}) => {
                this.forms = data
                this.forms.forEach(form => {
                    if (form.data && form.data.fields) {
                        let files = form.data.fields.filter(x => {
                            if (x.field && x.field.type && x.field.type == 'file' && x.value) return x
                        })
                        console.log('files', files)
						files.map(x => {
							if (!x.value.includes('https')){
								console.log('DOESN"T INCLUDE')
								x.value = 'https://' + x.value
								return x
							}
							return x
						})
                        this.attachments = this.attachments.concat(files)
                    }
                })
                this.loading = false
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
            if (this.allContacts[this.id] && this.allContacts[this.id].others && this.allContacts[this.id].others.attachments) {
                this.attachments = this.attachments.concat(this.allContacts[this.id].others.attachments)
            } else if (this.externalContacts[this.id] && this.externalContacts[this.id].others && this.externalContacts[this.id].others.attachments) {
                this.attachments = this.attachments.concat(this.externalContacts[this.id].others.attachments)
            }
        } else if ((this.id == '' || !this.id || this.id == undefined) && Object.keys(this.contact).length != 0 && this.contact && this.contact != undefined) {
            this.loading = true
            this.$api.get(`/form-submits/:instance?contact=${this.contact.id}`).then(({data}) => {
                this.forms = data
                this.forms.forEach(form => {
                    if (form.data && form.data.fields) {
                        let files = form.data.fields.filter(x => {
                            if (x.field && x.field.type && x.field.type == 'file' && x.value && x.value.includes('https')) return x
                        })
                        console.log('files', files)
						files.map(x => {
							if (!x.value.includes('https')){
								console.log('DOESN"T INCLUDE')
								x.value = 'https://' + x.value
								return x
							}
							return x
						})
                        this.attachments = this.attachments.concat(files)
                    }
                })
                this.loading = false
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
            if (this.allContacts[this.contact.id] && this.allContacts[this.contact.id].others && this.allContacts[this.contact.id].others.attachments) {
                this.attachments = this.attachments.concat(this.allContacts[this.contact.id].others.attachments)
            } else if (this.externalContacts[this.contact.id] && this.externalContacts[this.contact.id].others && this.externalContacts[this.contact.id].others.attachments) {
                this.attachments = this.attachments.concat(this.externalContacts[this.contact.id].others.attachments)
            }
        }

		for (let index = 0; index < this.attachments.length; index++) {
			const attachment = this.attachments[index];
			let i = index

			if (!attachment.label && attachment.name) {
				attachment.label = attachment.name
				attachment.value = attachment.url
			}

			if (attachment.isPrivate) {
				this.$api.post(`upload/files/get-signed-url/${attachment.id}`).then(({ data }) => {
					attachment.value = data;
					this.attachments[i] = attachment
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			}
		}
    }
}
</script>

<style scoped>
.action-button{
    cursor:pointer;
}
.action-button:hover{
    color:var(--orange);
}
</style>
<style>
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding: 7px 0;
}
</style>
