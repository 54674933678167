<template>
    <div>
        <EditDrawer @update="updateNote" />
        <BHLoading :show="loading" />
        <a-card>
            <div class="dF jE" slot="title">
                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                    <div slot="title">Add New Note</div>
                    <a-icon @click="addNote" style="font-size:25px; color:var(--orange); cursor:pointer" type="plus-circle" />
                </a-tooltip>
            </div>
            <template v-if="notes.length">
                <div :style="noteI != 0 ? 'padding-top:32px' :''" v-for="(note, noteI) in notes" :key="note.id + noteI" :value="note.id + noteI">
                    <div class="dF aT jSB">
                        <div class="dF aT">
                            <div class="smallIcon mr-3" style="background-color: #FFDEAD;">
                                <svg width="12" height="12" viewBox="0 0 20 20" fill="#F79425"><path id="Icon_material-comment" data-name="Icon material-comment" d="M22.99,5A2,2,0,0,0,21,3H5A2.006,2.006,0,0,0,3,5V17a2.006,2.006,0,0,0,2,2H19l4,4ZM19,15H7V13H19Zm0-3H7V10H19Zm0-3H7V7H19Z" transform="translate(-3 -3)"/> </svg>
                            </div>
                            <div>
                                <h6 style="color:var(--orange)">{{`Subject: ${note.subject}`}}</h6>
                                <div style="color:#000">{{note.content}}</div>
                                <div>{{convertDate(note.updatedAt)}}</div>
                            </div>
                        </div>
                        <div class="dF">
                            <div class="dF mr-3">
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Quick View</template>
                                    <a-icon @click="$store.commit('OPEN_PREVIEW_MODAL', {type:'note', object:note})" type="eye" class="icon-button mr-2" />
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot="title">Edit Note</template>
                                    <svg @click="editNote(note.id)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 icon-button"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </a-tooltip>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                    <template slot='title'>Delete Note</template>
                                    <i @click="deleteNote(note.id)" style="color:var(--danger)" class="fe fe-trash-2 icon-button" />
                                </a-tooltip>
                            </div>
                            <div>
                                <a-tooltip slot="extra" overlayClassName="change-tooltip-color" style="cursor:pointer">
                                    <template slot="title">{{`${getUser(note.user).firstName} ${getUser(note.user).lastName}`}}</template>
                                    <a-avatar v-if="getUser(note.user) && getUser(note.user).avatar" :src="getUser(note.user).avatar" shape="circle" :size="30"/>
                                    <a-avatar v-else-if="getUser(note.user).firstName && getUser(note.user).lastName">{{getUser(note.user).firstName[0].toUpperCase() + getUser(note.user).lastName[0].toUpperCase()}}</a-avatar>
                                </a-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>This opportunity doesn't have any notes</template>
        </a-card>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import EditDrawer from '@/components/contacts/EditDrawer'
export default {
    props:{
        id:{
            type:String,
            default:''
        }
    },
    components:{
        BHLoading,
        EditDrawer
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        opportunities() {
            return this.$store.state.contacts.opportunities || []
        },
        notes() {
            let notes = []
            console.log('run again')
            let time = this.update
            if (this.id != '' && this.opportunities.find(x => x.id == this.id)) {
                notes = this.opportunities.find(x => x.id == this.id).notes
            }
            return notes
        },
        users () {
            return this.$store.state.contacts.users || []
        },
    },
    data() {
        return{
            loading:false,
            update:0
        }
    },
    methods:{
        updateNote() {
            console.log('run')
            this.update = Date.now()
        },
        addNote() {
            this.$store.commit('ADD_NEW', 'Note')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', this.id)
        },
        editNote(id) {
            this.$store.commit('OPEN_EDIT_DRAWER', {type:'note', id:id})
            console.log('editnote',id)
        },
        deleteNote(id) {
            console.log('deletenote', id)
            let self = this
            this.$confirm({
                title: "Delete Note",
                content: h => <div>Do you want to delete this Note?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.loading = true
					self.$api.delete(`/comments/:instance/${id}`).then( ({data}) => {
						self.loading = false
						let index = self.notes.findIndex(x => x.id == data.id)
						if (index != -1) self.notes.splice(index, 1)
						self.$store.commit('DELETE_NOTE', data)
					}).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        convertDate(date) {
            if (date != undefined && date != '') {
                let year = date.substring(0,4)
                let month = date.substring(5,7)
                let day = date.substring(8,10)
                if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
				else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
				else {
					return `${month}/${day}/${year}`
				}
            }
        },
        getUser(id) {
            let find = this.users.find(x => x.id == id)
            if (find) return find
            else return {}
        },
    }
}
</script>

<style scoped>
.icon-button{
    cursor: pointer;
}
.icon-button:hover{
    color:var(--orange)
}
.smallIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding: 7px 0;
}
</style>
